.page {

  .content-container {
    padding-top: 40px;
    padding-bottom: 40px;
    h2 {
      color: $teal;
      margin-bottom: 1em;
    }

    .text-section-1col {
      margin-top: 25px;
    }

    p {
      a {
        font-weight: bold;
      }
    }
  }
}