footer {

  background-color: $purple;

  h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6, * {
    color: $white;
  }

  position: relative; // doit avoir une position pour avoir un z index
  overflow: hidden;
  padding: 6.25rem 0 2.5rem 0;
  max-height: 456px;

  .container {
    position: relative; // doit avoir une position pour avoir un z index
    z-index: 2;
    .h5 {
      margin-bottom: 1.25rem;
    }

    .h3 {
      margin-bottom: 2.5rem;
    }
  }

  .copyright {
    margin-top: 6.5rem;
    font-size: $font-size-sm;
  }

  .arrow-top {
    display: flex;
    justify-content: center;
    align-items: center;

    float: right;

    position: relative;
    right: 2.5rem;
    bottom: 10rem;

    border-radius: 100%;
    background-color: rgba(255, 255, 255, 0.1);

    width: 50px;
    height: 50px;

    cursor: pointer;
    z-index: 200;
    img {
      height: 40px;
    }
  }

  @include media-breakpoint-down(sm) {
    max-height: unset;
    padding-bottom: 1em;
    padding-top: 3em;
    text-align: center;
    .h3 {
      font-size: $h4-font-size;
    }
    a {
      margin-bottom: 1em;
    }
    .copyright {
      margin-top: 1.5rem;
      font-size: $font-size-sm;
    }
    .arrow-top {
      right: 20px;
      bottom: 50px;
    }
  }

}