.site-header {

  background-color: $purple;
  color: $white;

  z-index: 10;
  display: block;
  position: absolute;
  top: 0px;
  right: 0;
  left: 0px;

  height: 130px;

  padding: 100px 40px 40px;

  @include media-breakpoint-down(md) {
    padding: 20px;
  }

  .logo {
    display: inline-block;

    img {
      width: 200px;

      @include media-breakpoint-down(md) {
        margin-top: 60px;
        width: 150px;
      }

    }
  }

  nav {
    display: inline-block;
    float: right;

    ul {
      li {
        a {
          color: $white;
          border-radius: 22px;
          padding: 13px 28px 12px 30px;

          font-size: 16px;
          font-weight: bold;
          letter-spacing: 1px;

          &.contact {
            background: $white;
            color: $purple;
          }

          &:hover {
            &:not(.active) {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }

  .install-me-nav {
    background-color: #5466CA;
    height: 60px;
    position: absolute;
    width: 100%;
    top: 0;
    right: 0;
    padding-top: 5px;

    p {
      text-align: center;
    }
  }
}
