.btn {
  font-family: $font-family-base;
  font-size: $font-size-base;
  font-weight: bold;
  color: $white;
  padding: 16px 26px 15px 30px;
  border: none;
  outline: none;
  line-height: 1;
  border-radius: 25px;
  margin-right: 15px;
}

.btn-picto {
  margin-right: .625rem;
}
