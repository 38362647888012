$white:    #fff;
$gray-100: #f8f9fa;
$gray-200: #f5f5f5;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black:    #000;

$grays: ();
$grays: map-merge((
        "100": $gray-100,
        "200": $gray-200,
        "300": $gray-300,
        "400": $gray-400,
        "500": $gray-500,
        "600": $gray-600,
        "700": $gray-700,
        "800": $gray-800,
        "900": $gray-900
), $grays);

$blue:    #5466CA;
$indigo:  #6610f2;
$purple:  #45447B;
$pink:    #e83e8c;
$red:     #eb4e38;
$orange:  #fd7e14;
$yellow:  #FFC184;
$green:   #0D986A;
$very-light-green:   #f1f6f0;
$teal:    #00BFA5;
$cyan:    #17a2b8;

$primary:       $teal;
$primary-dark:  darken($primary, 15%);
$primary-darker: darken($primary, 30%);
$secondary:     $blue;
$success:       $green;
$info:          $cyan;
$warning:       $yellow;
$danger:        $red;
$light:         $gray-100;
$dark:          $gray-900;

$body-bg:                   $white;
$body-color:                $purple;

$link-color:                $primary;
$link-decoration:           none;
$link-hover-color:          darken($link-color, 15%);
$link-hover-decoration:     none;

$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px
);

$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1080px
);

$grid-columns:                12;
$grid-gutter-width:           20px;

$line-height-lg:              1.5;
$line-height-sm:              1.5;

$border-width:                1px;
$border-color:                $gray-300;

$border-radius:               14px;
$border-radius-lg:            20px;
$border-radius-sm:            9px;


$font-family-sans-serif:      'Montserrat', sans-serif;
$font-family-monospace:       SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
$font-family-base:            $font-family-sans-serif;

$font-size-base:              1rem; // 16px
$font-size-lg:                1.5rem; // 24px
$font-size-sm:                ($font-size-base * .875); // 14px

$font-weight-light:           400;
$font-weight-normal:          400;
$font-weight-bold:            700;
$font-weight-black:           900;

$h1-font-size:                $font-size-base * 4.8125; // 77px
$h2-font-size:                $font-size-base * 3.75; // 60px
$h3-font-size:                $font-size-base * 3.125; // 50px
$h4-font-size:                $font-size-base * 2.25; // 36px
$h5-font-size:                $font-size-base * 1.5; // 24px
$h6-font-size:                $font-size-base; // 16px

$font-family-secondary:        'Roboto', sans-serif;
$headings-font-family :        $font-family-sans-serif;
$headings-font-weight:        700;
$headings-line-height:        1.2;
$headings-color:              $purple;

$shadow:	    	0 3px 30px rgba(black, .05);
$shadow-button: 	0 0 1rem rgba(black,.15);
$shadow-button-sm:  0 0 1rem rgba(black,.1);

$input-focus-border-color: rgba($primary, .2);
$input-btn-focus-color: rgba($primary, .2);

$left-sidebar-w: 300px;
$top-menu-h: 80px;


