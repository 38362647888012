@import "variables";
// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';
@import '~ldbutton/dist/ldbtn.min.css';
@import '~@loadingio/loading.css/dist/loading.min.css';

@import "components/buttons";
@import "components/header";
@import "components/footer";
@import "components/burgerMenu";
@import "pages/homepage";
@import "pages/page";
@import "pages/404";
@import "pages/contact";
@import "pages/project";

// Ajustements globaux

.highlight {
  color: $teal !important;
}

body {
  line-height: 1.2;
  overflow-x: hidden;
}

p {
  line-height: 1.8;
}

.h1, .h2, .h3, .h4, .h5, .h6 {
  letter-spacing: .1px;
}

// Shapes
body.menu-opened {
  .shape-1 {
    transition: opacity .2s ease-out;
    opacity: 0 !important;
    height: 0 !important;
    overflow: hidden;
  }
}

#main {

  img[class^="shape-"] {
    position: absolute;
    z-index: 0;
    height: 1500px;
    @include media-breakpoint-down(md) {
      height: 800px;
    }
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  .shape-1 {
    opacity: 1;
    transition: opacity .3s ease-out;
    top: 0;
    right: 0;
    transform: translate(1060px, -320px);
    @include media-breakpoint-down(md) {
      transform: translate(500px, -90px);
    }
    @include media-breakpoint-down(sm) {
      transform: translate(550px, -150px);
    }
  }

  .shape-2 {
    top: 0;
    left: 0;
    transform: translate(-750px, 610px);
    @include media-breakpoint-down(md) {
      transform: translate(-500px, 910px);
    }
    @include media-breakpoint-down(sm) {
      transform: translate(-500px, 760px);
    }
  }

  .shape-3 {
    top: 0;
    right: 0;
    transform: translate(1060px, 1670px);
    @include media-breakpoint-down(md) {
      transform: translate(500px, 1670px);
    }
    @include media-breakpoint-down(sm) {
      transform: translate(500px, 1470px);
    }
  }

  .shape-4 {
    top: 0;
    right: 0;
    transform: translate(750px, -155px);
    @include media-breakpoint-down(md) {
      transform: translate(500px, 0);
    }
    @include media-breakpoint-down(sm) {
      transform: translate(600px, -150px);
    }
  }
}

// Pictos

$pictos: 'dev-spe', 'e-commerce', 'site-vitrine', 'hebergement', 'perf', 'seo', 'send', 'telephone', 'up', 'facebook', 'instagram', 'linkedin', 'malt', 'burger', 'times';

.picto {
  height: 50px;
  display: block;
}

@each $picto in $pictos {
  .picto-#{$picto} {
    background: url(/images/#{$picto}.svg);
    background-repeat: no-repeat;
  }
}

.social-networks {
  position: absolute;
  right: 30px;
  bottom: 30px;

  @include media-breakpoint-down(sm) {
    right: 15px;
    bottom: 15px;
  }

  .picto {
    width: 40px;
    margin-left: 8px;
    display: inline-block;
  }
}

// Loader
#root:empty {
  background: url(/images/loading-logo.svg) center center;
  background-repeat: no-repeat;
  background-size: 300px 150px;
  content: "";
  position: absolute;
  height: 100vh;
  width: 100vw;
}

#root:not(empty) {
  position: relative;
  overflow: hidden;
}

// Header
#main {
  padding-top: 130px; // Header
}

img {
  max-width: 100%;
}