.project .site-header {
  background: transparent;
}

.project-banner {

  margin-top: -130px; // header
  height: 100vh;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  * {
    color: $white;
  }

  display: flex;
  justify-content: center;
  align-items: center;

  .banner-overlay {
    z-index: 0;
    animation: fadein 3s;
    background-color: $purple;
    opacity: .9;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .banner-content {

    position: relative;
    z-index: 20;
    margin-top: 120px;
    display: block;

    .banner-content-half {
      max-width: 520px;
    }

    .highlight {
      font-size: $font-size-base;
      font-weight: bold;
    }

  }

  @include media-breakpoint-down(sm) {
    text-align: center;
    .h1:not(.highlight) {
      font-size: $h3-font-size;
    }
  }

}

.project-description {
  background-color: $purple;
  padding-top: 60px;
  padding-bottom: 60px;

  * {
    color: $white;
  }

  img {
    width: 100%;
  }

  .embed.embed-youtube {
    width: 100%;

    iframe {
      width: 100%;
      height: auto;
      min-height: 500px;
    }
  }

  h1, h2, h3, h4, h5, h6 {
    margin-bottom: 1.5rem;
  }
}

.project-gallery {
  background-color: $white;
  padding-bottom: 110px;
  padding-top: 100px;

  .swiper-container {
    .swiper-slide {
      text-align: center;

      img {
        max-width: 100%;
      }

      .image-caption {
        position: absolute;
        bottom: -1rem;
        background: rgba(0, 0, 0, 0.7); /* Black see-through */
        width: 100%;
        transition: .5s ease;
        opacity: 0;
        color: white;
        font-size: 20px;
        padding: 20px;
        text-align: center;
      }

      &:hover .image-caption {
        opacity: 1;
      }
    }
  }

  .swiper-button-next, .swiper-button-prev {
    color: $teal;
  }

  .swiper-pagination-bullet {
    background: $teal;
  }
}

.project-preview {
  margin: 4em 2em;
  padding: 0 1em;
  .h5 {
    margin-top: 2em;
  }
  img {
    background-color: $light;
  }
  border-left: 2px solid $teal;
}