.page-contact {

  .content-container {
    padding-top: 40px;
    padding-bottom: 40px;
    .h2 {
      //color: $teal;
      margin-bottom: 1em;
    }

  }
}