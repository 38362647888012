.homepage {

  .canvasContainer {
    background: transparent;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }
  .site-header {
    background: transparent;
  }

  .homepage-banner {

    margin-top: -130px; // header

    height: 100vh;
    //width: 100vw;
    background-position: top center;
    background-repeat: no-repeat;
    //background-attachment: fixed;
    background-size: cover;
    //background: linear-gradient(to bottom, rgba(69, 71, 124, 0) 80%, rgba(69, 71, 124, 1) 100%), url(/images/hero-header.jpg);
    background-image: url(/images/hero-header.jpg);

    * {
      color: $white;
    }

    display: flex;
    justify-content: center;
    align-items: center;

    .banner-content {

      position: relative;
      z-index: 20;
      margin-top: 120px;
      display: block;

      .banner-content-half {
        max-width: 520px;
      }

      .highlight {
        font-size: $font-size-base;
        font-weight: bold;
      }

      .h1:not(.highlight) {
        margin-bottom: 20px;
      }

    }

    @include media-breakpoint-down(sm) {
      text-align: center;
      .h1:not(.highlight) {
        font-size: $h3-font-size;
      }
      a {
        margin-bottom: 1em;
      }
    }

  }

  .homepage-projects {

    background-color: $purple;

    * {
      color: $white;
    }

    padding-top: 215px;
    padding-bottom: 160px;

    @include media-breakpoint-down(md) {
      padding-top: 120px;
      padding-bottom: 120px;
    }
    @include media-breakpoint-down(sm) {
      padding-top: 60px;
      padding-bottom: 60px;
    }

    .projects-header {
      margin-bottom: 2em;
      align-items: center;
      justify-content: space-between;
      display: flex;

      @include media-breakpoint-down(md) {
        display: block;
      }

      a {
        color: $white;
        font-weight: bold;
        padding-top: 1.5em;

        &:after {
          content: "|";
          color: $teal;
          margin-left: .3em;
        }
      }
    }

    .projects-list {

      display: flex;
      flex-flow: wrap-reverse;
      justify-content: flex-start;
      flex-direction: column;
      padding-top: 1em;
      padding-bottom: 2em;

      @include media-breakpoint-up(sm) {
        &:last-child {
          padding-left: 6em;
        }
      }

      .projects-tagline {
        margin-top: 2.5em;
        line-height: 30px;
        @include media-breakpoint-down(sm) {
          display: none;
        }
      }

      .preview-project {

        max-width: 430px;
        margin-top: 5em;

        img {
          max-width: 100%;
          width: 100%;
          margin-bottom: 20px;
        }

        &.small {
          max-width: 340px;
          margin-top: 7.5em;
        }

        &.big {
          max-width: 520px;
          margin-top: 0;
        }

        @include media-breakpoint-down(sm) {
          &.small, &.medium {
            max-width: 520px;
          }
        }
      }

    }
  }

  .homepage-skills {

    position: relative;
    background-color: $white;
    padding-bottom: 110px;
    padding-top: 100px;

    @include media-breakpoint-down(md) {
      padding-bottom: 30px;
    }
    @include media-breakpoint-down(sm) {
      padding-bottom: 0;
      padding-top: 60px;
    }

    * {
      color: $purple;
    }

    .h3 {
      text-align: center;
      margin-bottom: 1.2em;
      letter-spacing: .1px;
    }

    .skill-preview {

      margin-bottom: 4.5em;

      div {
        border-left: 2px solid $teal;
        padding-left: 1.5em;
        padding-top: 1em;

        .picto {
          margin-bottom: 1em;
        }

      }

      p {
        padding-top: .5em;
        padding-left: 2em;
        font-size: $font-size-sm;
      }

    }

  }

  .homepage-experiences {

    position: relative;
    padding-top: 60px;
    padding-bottom: 25px;
    overflow: hidden;

    @include media-breakpoint-down(md) {
      padding-bottom: 35px;
    }
    @include media-breakpoint-down(sm) {
      padding-top: 60px;
      padding-bottom: 35px;
    }

    background: $gray-200;

    @include media-breakpoint-down(md) {
      .circle {
        display: none;
      }
      .img-container img {
        display: none;
      }
    }

    *:not(.highlight) {
      color: $purple;
    }

    .circle {
      position: absolute;
      background: $teal;
      border-radius: 100%;
      height: 1258px;
      width: 1258px;
      left: 0;
      transform: translate(-635px, -31%);
      z-index: 0;
    }

    .experiences-container {
      background-color: $gray-200;
      border: none;
      border-radius: 50px;
      padding: 40px;
    }
    .img-container {

      align-items: center;
      display: flex;

      img {
        border-radius: 50%;
        width: 340px;
        height: 340px;
      }
    }

    .experiences-title {
      margin-bottom: 1.2em;
    }

    .experience-preview {
      margin-bottom: 34px;

      .h5 {
        margin-bottom: 16px;
      }

      p {
        font-size: $font-size-sm;
        margin-left: 1.5em;
        padding-left: 16px;
        border-left: 3px solid $teal;
      }
    }

  }

}