.leftbar {
  position: fixed;
  right: 0;
  top: 0;
  height: 100%;
  width: $left-sidebar-w;
  background-color: $teal;
  z-index: 10002;
  color: $white;

  @include media-breakpoint-up(lg) {
    display: none;
  }
  @include media-breakpoint-down(lg) {
    transition: .2s ease;
    transform: translateX(100%);

    &.is-active {
      transform: translateX(0);
    }
  }
}

.leftbar__content {
  display: flex;
  height: calc(100% - 80px);
  flex-direction: column;
  justify-content: space-between;
}

.leftbar__menu {
  margin-top: 20px;

  button {
    background: transparent;
    border: none;
    left: 125px;
    position: relative;

    .close-menu {
      width: 30px;
      height: 30px;
    }
  }

}

.leftbar__bottom, .leftbar__menu {
  text-align: center;

  ul {
    margin-top: 10px;
    list-style: none;
    padding: 0;
  }

  a {
    color: $white;
    padding: 13px 40px 12px 40px;

    font-size: 18px;
    font-weight: bold;
    letter-spacing: 1px;
  }
}

.leftbar-overlay {
  position: fixed;
  display: none;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(black, .5);
  z-index: 10001;
}

.leftbar.is-active + .leftbar-overlay {
  display: block;
}

.leftbar__bottom {
  padding-top: 20px;
  border-top: 1px solid $purple;
}

.top-menu__toggle-menu {
  width: 50px;
  height: 40px;
  border: none;
  border-left: 1px solid $teal;
  background: none;
  color: $white;
  font-size: 1.5rem;
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 250;
}
