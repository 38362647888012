.not-found {

  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;

  h1 {
    color: $teal;
  }

}
